import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import { getRootPersistConfig, getRootReducer } from "./reducers/rootReducer";

// ----------------------------------------------------------------------

const getStore = (uuid) => {
  if (!uuid) {
    return null;
  }
  return configureStore({
    reducer: persistReducer(getRootPersistConfig(uuid), getRootReducer(uuid)),
    middleware: getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
  });
};

const getPersistor = (store) => persistStore(store);

const useSelector = useReduxSelector;

const useDispatch = () => useReduxDispatch();

export { getStore, getPersistor, useSelector, useDispatch };
