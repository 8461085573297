import { isEmpty } from "lodash";
import { useCallback, useEffect, useRef } from "react";
import { startCheckoutEvent, viewCartEvent } from "src/hooks/useAnalytics";
import useConfig from "src/hooks/useConfig";
import track from "src/utils/analytics";

export default function GoogleAnalyticsTrackPage({
  currency,
  isCartPage = false,
  isCheckoutPage = false,
  cart = [],
  children,
}) {
  const { config } = useConfig();
  const measurementId = config?.metadata?.measurementId;
  const trackPath = useRef(null);

  const handleAdditionalEvents = useCallback(() => {
    if (!currency || isEmpty(cart)) {
      return;
    }

    if (isCartPage) {
      viewCartEvent(currency, cart);
    }

    if (isCheckoutPage) {
      startCheckoutEvent(currency, cart);
    }
  }, [currency, cart, isCartPage, isCheckoutPage]);

  useEffect(() => {
    if (
      trackPath.current === window.location.pathname ||
      !window.gtag ||
      !measurementId
    ) {
      return;
    }

    trackPath.current = window.location.pathname;
    track.pageview(measurementId, {
      page_path: window.location.pathname,
    });

    handleAdditionalEvents();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.gtag, measurementId, handleAdditionalEvents]);

  return children;
}
