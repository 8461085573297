import { Helmet } from "react-helmet-async";
import useConfig from "src/hooks/useConfig";

export default function GoogleAnalytics() {
  const { config } = useConfig();
  const measurementId = config?.metadata?.measurementId;
  if (!measurementId) {
    return null;
  }
  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${measurementId}`}
      />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }

          gtag('js', new Date());
          gtag('config', '${measurementId}');
        `}
      </script>
    </Helmet>
  );
}
