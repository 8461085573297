import axios from "axios";

// ----------------------------------------------------------------------
const TIMEOUT_IN_MS = 20000;

const axiosInstance = axios.create({
  timeout: TIMEOUT_IN_MS,
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

axiosInstance.interceptors.request.use(
  (request) => {
    request.headers["x-host"] = window.location.hostname;
    return request;
  },
  (error) => error
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance;
