import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import storeReducer from "../slices/store";
import productReducer from "../slices/product";
import ProductExpireTransform from "../transforms/productExpireStoreTransform";

// ----------------------------------------------------------------------

const getRootPersistConfig = (uuid) => ({
  key: `${uuid}-root`,
  storage,
  keyPrefix: "prs-",
  whitelist: [],
});

const getStorePersistConfig = (uuid) => ({
  key: `${uuid}-store`,
  storage,
  keyPrefix: "prs-",
  whitelist: ["currency"],
});

const getProductPersistConfig = (uuid) => ({
  key: `${uuid}-product`,
  storage,
  keyPrefix: "prs-",
  whitelist: ["query", "cart", "recentlyViewedProducts"],
  transforms: [ProductExpireTransform],
});

const getRootReducer = (uuid) =>
  combineReducers({
    store: persistReducer(getStorePersistConfig(uuid), storeReducer),
    product: persistReducer(getProductPersistConfig(uuid), productReducer),
  });

export { getRootPersistConfig, getRootReducer };
