import PropTypes from "prop-types";
import { useEffect } from "react";
import { getCurrencyOptions } from "src/redux/slices/store";
import { useDispatch } from "../redux/store";

ConfigOptionsProvider.propTypes = {
  children: PropTypes.node,
};

export default function ConfigOptionsProvider({ children }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrencyOptions());
  }, [dispatch]);

  return children;
}
