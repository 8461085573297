const setup = (...args) => {
  // Comment when for testing
  if (process.env.NODE_ENV !== "production") {
    return;
  }
  if (!window.gtag) {
    return;
  }
  window.gtag(...args);
};

const track = {
  pageview: (measurementId, props) => {
    setup("config", measurementId, props);
  },
  event: (type, props) => {
    setup("event", type, props);
  },
};

export default track;
