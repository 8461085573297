import PropTypes from "prop-types";
import { createContext, useEffect, useState } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { handleGetStoreConfig } from "src/service/host";
import { getStore, getPersistor } from "../redux/store";

// ----------------------------------------------------------------------

const initialState = {
  theme: null,
};

const ConfigContext = createContext(initialState);

ConfigProvider.propTypes = {
  children: PropTypes.node,
};

function ConfigProvider({ children }) {
  const [config, setConifg] = useState(null);
  const tenantUuid = config?.metadata?.id;
  const reduxStore = getStore(tenantUuid);

  useEffect(() => {
    const initialise = async () => {
      await handleGetStoreConfig()
        .then((response) => setConifg(response.data))
        .catch(() => setConifg(null));
    };
    initialise();
  }, []);

  return (
    config && (
      <ConfigContext.Provider
        value={{
          config,
        }}
      >
        <ReduxProvider store={reduxStore}>
          <PersistGate persistor={getPersistor(reduxStore)}>
            {children}
          </PersistGate>
        </ReduxProvider>
      </ConfigContext.Provider>
    )
  );
}

export { ConfigProvider, ConfigContext };
